


export interface AudioInputInfo {
    label: string,
    deviceId: string
}



export const getAvailableAudioDevices =  async():Promise<AudioInputInfo[]> => {

    await navigator.mediaDevices.getUserMedia({audio: true, video: false});
    let devices = await navigator.mediaDevices.enumerateDevices();

    return devices
    .filter((device:MediaDeviceInfo) => device.kind==="audioinput")
    .map(( device:MediaDeviceInfo):AudioInputInfo => ({label:device.label, deviceId: device.deviceId}))   
}



export const getVolume  = (rawAudio:Float32Array) => {
	const total =rawAudio.reduce((acc, elem) => acc + elem*elem, 0); 
	return Math.sqrt(total/(rawAudio.length));
}


export const isClap = (rawAudio:Float32Array, sens:number) => {
    return rawAudio.some( (raw:number) => Math.sqrt(raw) >= 0.04 * sens )
  }



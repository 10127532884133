
//Libs
import React from 'react';
import {Box, Text, TextInput} from 'grommet'

const NameInput = (props:any):JSX.Element => {
    const onChange= (event:any) => {
        props.peerInterface.sendName(event.target.value)
    } 
    return (
            <Box direction='column' align='center' gap="xsmall"> 
                <Text size="xsmall">Microphone Name:</Text>
                <TextInput onChange={onChange}/>
            </Box>

      
    );
  }

export default NameInput;
import store from '../context/store'
import { setState} from '../context/recordingSlice'
import { Peerer } from './peer';
import isClapWorkerNode from './workletnode'
import {drawVolume} from "./draw"
import theme from "../components/Theme"

//https://stackoverflow.com/questions/48757933/audiocontext-issue-on-safari?rq=1
declare global {
    interface Window {
      AudioContext: typeof AudioContext;
      webkitAudioContext: typeof AudioContext;
    }
  }

export const windowCtx =  window.AudioContext || window.webkitAudioContext;
const makeUserMediaOptions = (device:string):MediaStreamConstraints =>{
    return {
        "audio": {
            "deviceId":device,
            "autoGainControl": false,
            "echoCancellation":false,
            "noiseSuppression":false
        },
        "video": false
    };
}
/**
* This class choses which input device we want to record from and records raw data from the microphone
*/
export class Microphone {
    ctx: AudioContext;  
    peerer: undefined| Peerer;
    sens: number;
    inputDeviceId: string;
    format: Object
    totalVolume: number;
    nVolume:number;
    mediaRecorder: MediaRecorder | undefined;
    scriptNode: ScriptProcessorNode | undefined;
    audioStream: MediaStream | undefined;
    streamSource: MediaStreamAudioSourceNode|undefined;
    clapWorkerNode: AudioWorkletNode |undefined;


    constructor(ctx:AudioContext) {
        this.peerer=undefined;
        this.ctx = ctx;
        this.sens = 8.0;
        this.inputDeviceId = "default";
        this.totalVolume=0;
        this.nVolume=0;
        this.format = {};
        this.mediaRecorder=undefined;
        this.scriptNode=undefined;
        this.audioStream=undefined;
        this.streamSource=undefined;
        this.clapWorkerNode=undefined;
    }
    /**
    * @param {string} deviceId: the id of the microphone we want to listen to
    */

    setPeerer = (peerer:Peerer) => {
        this.peerer = peerer
    }

    setInputDevice = (deviceId:string) =>{
        this.inputDeviceId=deviceId;
        this.connectMic();
    }
    
    stopRecording = () => {
        if (this.mediaRecorder && this.mediaRecorder.state == "recording") {
            this.mediaRecorder.stop()
        }
    }
    maybeReset = () => {
        //This makes sure everything has stopped
        this.audioStream?.getTracks().forEach((track) => track.stop());
        this.streamSource?.disconnect();
        this.scriptNode?.disconnect();
        this.clapWorkerNode?.disconnect();
        this.mediaRecorder=undefined;        
    }

    connectMic = async () => {
        this.maybeReset()
        const options = makeUserMediaOptions(this.inputDeviceId)
        this.ctx.resume()
        this.audioStream = await navigator.mediaDevices.getUserMedia(options);
        this.streamSource = this.ctx.createMediaStreamSource(this.audioStream);
        this.mediaRecorder = new MediaRecorder(this.audioStream);
        this.mediaRecorder.ondataavailable = (event) => {
            this.peerer?.sendData(event.data)
        }
        this.connectWorkletClapDetector()  
    }

    setSens = (sens:number) => {
        this.sens = sens;
        if (this.clapWorkerNode) {
            const sensParam = this.clapWorkerNode.parameters.get("sens");
            sensParam?.setValueAtTime(sens, 0) 
        }

    }

    setPrimed = (isPrimed:boolean) => {
        if (this.clapWorkerNode) {
            const primedParam = this.clapWorkerNode.parameters.get("isPrimed");
            const newIsPrime = Number(isPrimed);
            primedParam?.setValueAtTime(newIsPrime, 0)          
        }
        else {
            console.warn("no clapDetector connected to prime");
        }

    }

    connectWorkletClapDetector = async () => {
        console.log("using audioworklet")
        try {
            await this.ctx.audioWorklet.addModule("processor.js")
            this.clapWorkerNode = new isClapWorkerNode(this.ctx);
            this.setSens(this.sens)
            this.clapWorkerNode.port.onmessage = (e) => {
                const recordingState = store.getState().recordingState
                switch(e.data.type) {              
                    case "clap":
                        this.mediaRecorder?.start(250);
                        this.setPrimed(false);
                        store.dispatch(setState(2));
                        this.peerer?.sendState(2)     
                        
                        break
                    case "vol":
                        const newVol =e.data.vol
                        putVolume(newVol, recordingState.state)
                        this.peerer?.sendVolume(newVol, recordingState.state)         
                }    
            }
            this.streamSource?.connect(this.clapWorkerNode);
            this.clapWorkerNode.connect(this.ctx.destination);
        }
        catch (e) {
            console.log(e)
        }
    }

}

const putVolume = (vol:number, state:number) => {
    
    drawVolume(vol, get_color(state));

}

const get_color = (state:number) => {
    if (state === 2) {
        return theme.global.colors.recording
    }

    if (state === 1) {
        return theme.global.colors.primed
    }
    return theme.global.colors.not_recording //maybe just black is ok?

}
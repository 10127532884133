


export const drawVolume = (volume:number, color:string):void => {
       const volumeCanvas = document.getElementById("volume_bar") as HTMLCanvasElement 
        if (volumeCanvas) {
            const ctx = volumeCanvas.getContext("2d");
            ctx!.fillStyle = color;

            ctx?.clearRect(0, 0, volumeCanvas.width, volumeCanvas.height);
            ctx?.fillRect(0, 0, volumeCanvas.width*volume, volumeCanvas.height );
        }
}

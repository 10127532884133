
//Libs
import React from 'react';
import {Box} from 'grommet'

//My Components
import ChoseMic from './ChoseMic';
import MicSens from './MicSens'
import VolumeBar from './VolumeBar';
import NameInput from './NameInput';
import { useSelector } from 'react-redux';
import { RootState } from '../../context/rootReducer';

const Record = (props:any):JSX.Element => {
    const projectId = useSelector((state:RootState) => state.recordingState.peerId); 

    return (
       
        <>
             {projectId?
             <Box align="center" justify="center" gap="large">
                <ChoseMic micInterface={props.micInterface} />                
                <VolumeBar/>
                <NameInput peerInterface={props.peerInterface}/>
            </Box>:
                "Connecting..."
             }
        </>

    );
  }

export default Record;
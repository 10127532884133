
//Libs
import {useEffect, useRef} from 'react'
import {Box} from 'grommet';

const VolumeBar = (props:any):JSX.Element => {
  const canvasRef = useRef<HTMLCanvasElement>(props.id);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.width  = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;

    }
  }, []);
    return (
      <Box border="all" height="100%" width="100%">
       <canvas  ref={canvasRef} id={"volume_bar"} style={{width:"100%", height:"200%"}}   ></canvas >
      </Box>
    
    );
  }

export default VolumeBar;

import { Box } from 'grommet';

const AppBar = (props: any):JSX.Element => (  
    <Box
      border={true}
      tag='header'
      direction='row'
      align='center'
      justify='between'
      background='behind'
       pad={{ left: 'large', right: 'xsmall', vertical: 'none' }}
       elevation='xsmall'
       style={{ zIndex: '1' }}
       {...props}
     />
 );
 export default AppBar
import React, {useEffect} from 'react';
import { Box, Heading, Text, Grommet } from 'grommet';
import { Provider } from 'react-redux';
//My Store
import store from '../context/store'

//My Theme
import theme from './Theme';
//My compotnents
import Record from './Record/Record';

import AppBar from './AppBar/AppBar';
//AudioContext and microphone and track
import {windowCtx, Microphone} from '../media/capture';
import {Peerer} from '../media/peer';

const ctx:AudioContext = new windowCtx();
const peerInterface: Peerer = new Peerer();
const micInterface:Microphone = new Microphone(ctx);
micInterface.setPeerer(peerInterface);
peerInterface.setMic(micInterface)


const App = ():JSX.Element => { 



  return (
    <Provider store={store}>
    <Grommet theme={theme}>
     <Box fill={true} >
      <AppBar>
        <Heading level='3'>
        <Text color='text' size="large"><b>Envelup</b></Text>
        </Heading>         
      </AppBar>
        
        <Record micInterface={micInterface} peerInterface={peerInterface} />
     </Box>
    </Grommet>
    </Provider>

  );
  }
export default App;

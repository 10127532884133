


//Libs
import {useState} from 'react';
import {RangeInput, Box, Text} from 'grommet';



const MicSens = (props:any):JSX.Element => {


  const [sens, setSens] = useState(props.micInterface.sens);
 // const [value, setValue] = useState(10);

  const change = (event:any) =>{
    
    const sens =  event.target.value 
    setSens(sens)
    props.micInterface.setSens(sens)

  } 

  return (
    <Box style = {{width:"90%"}} justify="center" >
        <Text size="xsmall">Sensitvity:</Text>     
    <RangeInput  
      value={sens}
      onChange={change}
      min = {1}
      max = {10}
      step = {0.1}

    />
    </Box>
  )
  
}

export default MicSens;

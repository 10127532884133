import { createSlice, PayloadAction } from '@reduxjs/toolkit';

//import { AppThunk, AppDispatch } from './store'


export interface AudioInputInfo {
    label: string,
    deviceId: string
}

export interface RecordingState {

    device: string |undefined;
    peerId: string | undefined;
    state: number


}
const initialState : RecordingState = { device:undefined, peerId:undefined, state:0};
const recordingStateSlice = createSlice({
    name: 'recordingState',
    initialState,
    reducers: {
        setState(state, action: PayloadAction<number>) {

            state.state = action.payload;
        },
 
        setDeviceConnected(state, action: PayloadAction<string>) {
            state.device = action.payload
        },

   
        setPeerId(state, action:PayloadAction<string|undefined>) {
            state.peerId=action.payload;

        }

    }
});

export const { setState, setDeviceConnected,  setPeerId } = recordingStateSlice.actions;

export default recordingStateSlice.reducer;
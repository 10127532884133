import './main.css'


const brandColor = '#261c15';


const theme = {

    
    global: {
      spacing: "10px",
      colors: {
        // text: '#261c15',
        // behind: '#11123',
        // second: '#f06d23',
       // brand: "purple",
        recording:'#c82536',
        primed:'#FFB347',
        not_recording: '#261c15',
        // selected: '#261c15',
        // focus: '#261c15',
        // primary: '#11123'
      },
      font: {
        //Major Mono Display
        family: 'Varela Round',
        size: '12px',
        // height: '10px'
      }
    }
  };


export const micColors= ["#E5E28B", "#A7DB8C", "#B4A7EB", "#F3A5BC", "#A0D8E9", brandColor]

export default theme;
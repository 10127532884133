import Peer from "peerjs";
import store from '../context/store'
import {setState, setPeerId} from '../context/recordingSlice'
import { Microphone } from "./capture";

const options = {
    config:{'iceServers': [{ 'urls': 'stun:stun.l.google.com:19302' }]},
    host: process.env.REACT_APP_PEERJS_SERVER,
    secure:true,
    key: "supersecret",
    path:"/myapp"         
};

const getProjectIdfromURL = ():string => {
    const url = window.location.href;
        let splits = url.split("/")
        return splits[splits.length-1];
  }

export class Peerer {
    peerer: Peer
    projectId: string
    myId: string |undefined
    conn:any
    mic: undefined | Microphone
    constructor() {
        this.projectId= getProjectIdfromURL();;
        this.myId=undefined;
        this.peerer = new Peer(options);
        this.peerer.on('open', this._handleOpen);
        this.conn = undefined;
        this.mic = undefined;
    }


    setMic = (mic:Microphone) => {
        this.mic = mic
    }


    sendData = (blob:Blob) => {
        this.conn.send({type:"micData", id:this.myId, value:blob})
    }

    sendName = (name:string) => {
        this.conn.send({type:"name", id:this.myId, value:name})

    }
    sendVolume = (volume:number, state:number) => {
        this.conn.send({type:"volume", id:this.myId, state, value:volume})
    }

    sendState = (state:number) => {
        this.conn.send({type:"state", id:this.myId, value: state})
    }

    _handleOpen = (myId:string) => {
        this.conn = this.peerer.connect(this.projectId);
        console.log(this.conn)
        this.conn.on("open", () =>  store.dispatch(setPeerId(myId)));
        this.conn.on("data", this._handleData);
        this.conn.on("close", this._handleClose);
        this.myId=myId;
        window.onpagehide = (e:any) => {
            this.conn.close();
        }

    }

    _handleClose = (conn:any) => {
        store.dispatch(setPeerId(undefined))


    }

    _handleData = (data:any) => {      
        switch (data.type) {
            case "state":
                this._handleState(data);
                this.sendState(data.value);
                break;
            case "reset":
                this._handleReset(data)
                break;
        }
          
    }

    _handleState = (data:any) => {
        this.mic?.setPrimed(data.value);
        store.dispatch(setState(data.value))
        if (data.value == 0 ) { //need to stop recording audio if told!
            this.mic?.stopRecording()    
        }
    }

    _handleReset = (data:any) => {
        store.dispatch(setState(0))
        if (this.mic?.mediaRecorder?.state !== "inactive" ) {
            this.mic?.mediaRecorder?.stop()      
        }
        this.mic?.setPrimed(false);
        this.mic?.connectMic();
    }
}